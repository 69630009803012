
  body{
    width: 100vw;
    height: 100vh;
    padding: 0;
    margin: 0;
  }


  .hover:hover{
    background-color: #FBEAD8;
  }


 @media only screen and (max-width: 1023px) {
    .cardBackground {
      background-image: url("../src/images/background-mobile.png");

    }
  }



 @media only screen and (min-width: 1024px) {
  .cardBackground {
    background-image: url("../src/images/background-desktop.png");
    background-size: cover;

  }
}



@media only screen and (min-width: 1px) {
    body {
      background-image: linear-gradient(#FFF0DD, #FDE1DF);
      background-size: cover;
    }
  }

  .hide {
    display: none;
  }
  @media only screen and (max-width: 1024px){
    .hide {
      display: block;
    }
  }
