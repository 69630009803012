@tailwind base;
@tailwind components;
@tailwind utilities;



/*
.cardContainer {
  max-width: 1300px;
  max-height: 800px;
}

.hide {
  display: none;
}
@media only screen and (max-width: 1024px){
  .hide {
    display: block;
  }
}


@media only screen and (min-width: 1024px) {
  .backgroundImage {
    background-image: linear-gradient(#FFF0DD, #FDE1DF);
  }
}

@media only screen and (max-width: 1023px) {
  .cardBackground {
    background-image: url('../src/images/background-mobile.png');


  }


}

@media only screen and (min-width: 1024px) {
  .cardBackground {
    background-image: url('../src/images/background-desktop.png');

  }
}


.mobile-background {
  background-image: url('../src/images/background-mobile.png');
}


.hover:hover{
  background-color: #FBEAD8;
}
 */


/* OLD------------------ */

/* .headshot {
  position: relative;
  display: inline-block;

}

.badgePosition {
  position: absolute;
  top: 160px;
  left: 260px;
} */


/*
.backgroundImage {
  width: 100%;

} */

/* .flipButton {
  position: relative;
  left: 0px;
  top: 470px;
  right: 255px;
  z-index: 1;

}

.arrowButton {
  position: relative;
  z-index: 1;
  top: 30px;
  left: 50px;
}


.logo-container {
  right: -10px;
  bottom: -10px;
} */



/* floral logo media queries */
/* @media only screen and (max-width: 600px) {
  .logo-container {
    right: -20px;
    top: 70px;
  }
}
@media screen and (max-width: 1023px) and (min-width: 768px){
  .logo-container {
    right: 0px;
    left: 70px;
    top: 70px;
  }
}

@media screen and (max-width: 1279px) and (min-width: 1024px){
  .logo-container {
    right: 0px;
    left: 40px;
    top: 50px;
  }
}

@media screen and (max-width: 1800px) and (min-width: 1280px){
  .logo-container {
    right: 0px;
    left: 60px;
    top: 50px;
  }
}

@media screen and (min-width: 1900px){
  .logo-container {
    right: 0px;
    left: 80px;
  }

} */